
import { mapActions } from 'vuex';

import { LANDING_PAGE_IDS, QUIZ_VARIANTS } from '@/services/constants';

export default {
  components: {
    LandingPagesLoseWeight: () => import('@/components/landing-pages/lose-weight/lose-weight'),
  },
  beforeRouteEnter(to, from, next) {
    const variant = to.params.variant;
    if (Object.values(QUIZ_VARIANTS).includes(variant)) {
      next();
    } else {
      next('/tour');
    }
  },
  head() {
    return {
      title: this.$t('landingPage.meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('landingPage.meta.description'),
        },
        {
          name: 'robots',
          content: 'noindex',
        },
      ],
    };
  },
  created() {
    this.setLandingPageIdAction(LANDING_PAGE_IDS.BODY_NUTRITION);
  },
  mounted() {
    this.landingPageGtmHandler();
  },
  methods: {
    ...mapActions('quiz', ['setLandingPageIdAction']),
  },
};
